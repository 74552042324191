import { Controller } from '@hotwired/stimulus'
import * as bootstrap from 'bootstrap'

// Connects to data-controller="popover"
export default class extends Controller {
  static values = {
    title: String,
    msg: String,
    placement: { type: String, default: 'top' }
  }

  connect () {
    this.popover = new bootstrap.Popover(this.element, {
      title: this.titleValue,
      content: this.msgValue,
      placement: this.placementValue,
      trigger: 'focus',
      html: true
    })
  }

  disconnect () {
    this.popover.dispose()
  }
}
