import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="autogrow-input"
export default class extends Controller {
  static targets = ["textInput"]

  static values = {
    initialDelay: { type: Number, default: 0 }
  }

  connect () {
    setTimeout(this.resizeInput.bind(this), this.initialDelayValue);
  }

  resizeInput() {
    // Set container element to the height of it's child text input to prevent page scroll jumping
    this.element.style.height = `${this.textInputTarget.scrollHeight}px`;

    // Reset the height of the text input to match it's text content
    this.textInputTarget.style.height = "0px";
    this.textInputTarget.style.height = `${this.textInputTarget.scrollHeight + 5}px`;

    // Adjust container to fit child textbox height
    this.element.style.height = "auto";
  }
}
