import { Controller } from '@hotwired/stimulus'
import SlimSelect from 'slim-select'

// Connects to data-controller="slim-select"

export default class extends Controller {
  static values = { addable: { type: Boolean, default: false }, showSearch: { type: Boolean, default: true } }

  connect () {
    const firstOpt = this.element.options[0]
    const showPlaceholder = typeof firstOpt !== 'undefined' && firstOpt.value === ''
    const promptText = showPlaceholder ? firstOpt.innerHTML : ''

    if (showPlaceholder) {
      firstOpt.dataset.placeholder = 'true'
    }

    this.slimselect = new SlimSelect({
      select: this.element,
      settings: {
        showSearch: this.showSearchValue,
        allowDeselect: showPlaceholder,
        placeholderText: promptText
      },
      events: {
        addable: this.addableValue ? (function (value) { return value }) : null
      }
    });
  }

  disconnect () {
    this.slimselect.destroy()
  }
}
